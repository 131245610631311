@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.SentOrderEditorScreen {
  $text-color: #124b7f;

  .content {
    flex-grow: 1;

    margin: 0 20px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      margin: 0 12px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 6%;
    }
  }

  .carousel {
    flex-grow: 1;

    .carousel-item {
      height: 100%;
    }

    .itemCardWrap {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .itemCard {
      $border-radius: 14px;

      background-color: #fff;
      border-radius: $border-radius;
      margin: 0 4px 16px;

      width: 66vw;
      padding: 3% 13% 24px;

      .imageWrap {
        $borderSize: 4px;
        $negativeMargin: 2px;

        position: relative;
        height: 0;
        width: calc(100% - #{($borderSize * 2) - $negativeMargin});
        margin-right: -$negativeMargin;
        padding-top: 100%;
        border: $borderSize solid #dcd9d9;
        border-radius: 16px;
        box-shadow: 2px 2px 4px #00000029;
        box-sizing: content-box; // to be exactly a square
        overflow: hidden; // To cut image corners since this is rounded.
      }

      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }

      .name {
        height: 50px;
        overflow-y: hidden;
        font-size: 16px;
      }

      &.not-received .receivedAmount .value,
      &.not-received .totalUnits {
        color: #e04d46;
      }

      &.partially-received .receivedAmount .value,
      &.partially-received .totalUnits {
        color: #ef9635;
      }

      &.received .receivedAmount .value,
      &.received .totalUnits {
        color: #52d058;
      }

      @include media-breakpoint-up(sm) {
        width: 50vw;
        padding: 3% 11% 24px;
      }

      @include media-breakpoint-up(md) {
        width: 45vw;
        padding: 3% 9% 24px;

        .name {
          font-size: 18px;
        }
      }

      @include media-breakpoint-up(lg) {
        width: 40vw;
        padding: 3% 12% 24px;

        .name {
          font-size: 24px;
        }
      }

      @include media-breakpoint-up(xl) {
        width: 32vw;

        .name {
          font-size: 26px;
        }
      }
    }
  }

  .label {
    color: $text-color;
  }
}
