@use "sass:selector";

@mixin sidebar-enabled {
  @at-root body.isSidebarEnabled & {
    @content;
  }
}

@mixin sidebar-disabled {
  @at-root body:not(.isSidebarEnabled) & {
    @content;
  }
}

@mixin sidebar-detached {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin sidebar-attached {
  @media only screen and (min-width: 1281px) {
    @include sidebar-enabled {
      @content;
    }
  }
}
