@import "~app/styles/variables.scss";

.ModalTitleBar {
  text-align: center;
  padding: 8px;
  position: relative;
  color: $blue-1;

  &.displayIndent:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: rgb(213, 206, 206);
  }

  .icon-wrap {
    img {
      width: 42px;
      height: 42px;
    }

    i {
      font-size: 42px;
      color: $blue-2;
    }
  }

  .close-btn {
    position: absolute;
    top: 12px;
    right: 0;
    bottom: 0;
    font-size: 28px;
    display: flex;
    padding: 0 12px;
    cursor: pointer;
    color: $blue-1;
  }

  .title {
    color: $blue-1;
  }
}
