.manager-dashboard {
  .data-box {
    width: 160px;
    height: 80px;
    border-radius: 5px;
    background-color: lightblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
