.main-tabs {
  &.nav-tabs {
    border-bottom: 3px solid saddlebrown;
  }

  .nav-item {
    margin: 0 4px;
    background-color: lightgray;
    color: white;

    &.active {
      color: white;
      background-color: #106784;
      border-bottom: unset;
    }

    &::before {
      display: none;
    }
  }
}
