.weeklyWorkScheduleForm {
  .table-container {
    overflow-x: auto;

    .table th,
    .table td {
      vertical-align: middle;
    }
  }
  table {
    margin-top: 20px;
    min-width: 800px;

    .dayHeader {
      text-align: center;
      width: 40px;
    }

    .dayCell input {
      text-align: center;
    }
  }
  table thead th {
    font-size: 16px;
    font-weight: normal;
  }

  @media only screen and (max-width: 1100px) {
    .table {
      input {
        padding: 0.35rem;
      }
      td {
        padding: 0.5rem 0.2rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .formHeader {
      flex-direction: column;
    }
  }
}
