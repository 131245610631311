@import "./SentOrderCard";
@import "./SentOrderScanScreen";
@import "./SentOrderScreen";

.EditSentOrderScreen {
  .backButton {
    font-size: 50px;
    color: #377a90;
    cursor: pointer;
  }
}

.SentOrderViewerScreen {
  .contentWrap {
    .cardsTableBodyWrap {
      .cards-table {
        .name-col {
          max-width: none;
          width: auto;
        }
      }
    }
  }
}
