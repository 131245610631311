@import "~app/components/SideBar/mixins.scss";

.TopBar {
  height: 55px;
  background-color: #0e5298;
  display: flex;
  justify-content: space-between;

  // Hiding when sidebar is attached.
  @include sidebar-attached {
    display: none;
  }

  .user-text-section,
  .actions-section {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    i {
      font-size: 30px;
    }
  }

  @include sidebar-enabled {
    .user-text-section {
      padding-right: 55px;
    }
  }
}
