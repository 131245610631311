/* happens on medium screens and less */
@media only screen and (max-width: 1280px) {
  .hide-lg {
    display: none;
  }
}

/* happens on tablets and less */
@media only screen and (max-width: 768px) {
  .hide-md {
    display: none;
  }
}

/* happens on tablets and less */
@media only screen and (min-width: 769px) {
  .only-md {
    display: none;
  }
}

/* happens on mobile and less */
@media only screen and (max-width: 440px) {
  .hide-xs {
    display: none;
  }
}

/* happens on medium screens and above */
@media only screen and (min-width: 1281px) {
  .hide-above-lg {
    display: none !important;
  }
}
