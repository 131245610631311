.OrdersStatusScreen {
  .top-bar {
    background-color: #17a2d6;
    border-radius: 0 60px 0 0;
    overflow: hidden; // So the border radius will cut off any overflown elements.

    .title {
      font-size: 24px;
      color: white;
      display: flex;
      align-items: center;
      padding: 12px 24px;

      .fa-shopping-cart {
        margin-right: auto;
      }
    }

    .nav {
      display: flex;
      .item {
        flex: 1;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        font-size: 20px;
        color: white;
        cursor: pointer;
      }

      .item.active {
        background-color: #0b71c0;
        cursor: default;
      }
    }

    @media only screen and (max-width: 1280px) {
      border-radius: 0 0 18px 18px;

      .title {
        padding: 12px;
      }
    }
  }
}
