@import "~app/styles/variables.scss";
@import "~app/components/SideBar/mixins.scss";

.OrdersBottomNavbar {
  position: fixed;
  background: #f2f2f2;
  height: $bottom-navbar-height;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 0px 10px 0 #a0a0a0;
  transition: bottom 0.4s; // show up slow

  display: flex;
  justify-content: center;

  &.isHidden {
    transition: bottom 0.2s; // disappear fast
    bottom: calc(-#{$bottom-navbar-height} - 10px);
  }

  @include sidebar-attached {
    right: $sidebar-width;
  }

  @include sidebar-detached {
    right: 0;
  }

  .item {
    position: relative;
    flex: 1 1 0px;
    max-width: 120px;
    border-left: lightgray 2px solid;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-right: lightgray 2px solid;
    }

    &.selected {
      border-bottom: blue 2px solid;
    }

    .counter {
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #169bd7;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      top: 4px;
      right: 22px;
      font-size: 16px;
    }
  }
}
