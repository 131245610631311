.simpleGlobalListContainer {
  .cards-table {
    max-width: 500px;
  }

  @media only screen and (max-width: 440px) {
    .cards-table {
      tbody tr td {
        padding: 10px !important;
      }
    }
  }
}

.simple-lists-index-container {
  display: inline-flex;
  flex-direction: column;
  > a {
    margin-top: 5px;
  }
}
