.main-menu-container {
  min-height: 90vh;
  align-items: center;
  justify-content: center;
  display: flex;
  .nav-link {
    border-bottom: 1px solid #e8e8e8;
  }
  .section {
    border-bottom: 1px solid #e8e8e8;
    .nav-link {
      border-bottom: none;
    }

    .icon-container {
      background: #333333;
      display: flex;
      align-self: stretch;
      align-items: center;
      justify-content: center;
      i {
        margin: 0 15px;
        transition: all 0.3s;
        &.collapsed {
          transform: rotate(0deg);
        }
        &.opened {
          transform: rotate(90deg);
        }
      }
      color: white;
      font-size: 24px;
      cursor: pointer;
    }
  }
  a {
    cursor: pointer;
    user-select: none;
    color: white;
  }
  .main-menu {
    flex-wrap: wrap;
    justify-content: center;
    .main-menu-header {
      text-align: center;
      font-size: 3rem;
    }
    .group-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > div {
        background: #23b8ce;
        margin: 10px;
        width: 300px;
        border-radius: 5px;
        > .menu-group-header {
          background-color: #92c03e;
          color: white;
          font-size: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 60px;
          border-radius: 5px;
        }

        > .menu-group-content {
          .collapsed-content {
            max-height: 0;
            overflow: hidden;

            background-color: #106784;
            > a {
              padding-right: 30px;
            }
            &.collapsed {
              max-height: 0;
              transition: max-height 0.3s ease-out;
            }
            &.opened {
              transition: max-height 0.3s ease-in;
              max-height: 200px;
            }
          }
          a {
            &:hover {
              background-color: #333333;
              transition: 200ms linear;
              color: white;
            }
          }
        }
        @media only screen and (max-width: 768px) {
          width: 47%;
          margin: 7px;
          .menu-group-header {
            height: auto;
          }

          .nav-link {
            padding: 0.2rem 0.5rem;
          }
        }
        @media only screen and (max-width: 500px) {
          width: 80%;
          margin: 7px;
          .menu-group-header {
            height: auto;
          }

          .nav-link {
            padding: 0.5rem 1rem;
          }
        }
      }
    }

    // .main-menu-items > a {
    //   width: 200px;
    //   margin: 15px;
    //   height: 200px;
    //   border-radius: 10px;
    //   text-align: center;
    //   color: initial;
    //   display: flex;
    //   flex-direction: column;
    //   :nth-child(1) {
    //     background: #7c0000;
    //   }
    //   a:nth-child(2) {
    //     background: #7c4400;
    //   }

    //   > div {
    //     display: flex;
    //     height: 100%;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     color: white;
    //     font-size: 18px;
    //   }
    // }
    // & > a:hover {
    //   text-decoration: none;
    //   filter: grayscale(30%);
    // }
  }
}
