.SentOrderCard {
  $header-text-color: #0d477c;
  $text-color: #124b7f;
  $border-radius: 14px;
  $divider-color: #e8e8e8;

  background-color: white;
  border: 1px solid $divider-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  transition: border-radius 0.5s;
  will-change: border-radius;

  margin: 5px 20px;
  overflow: hidden; // To hide the image overflow.

  &.isExpanded {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-color: #17a2d6;
  }

  .header {
    font-size: 18px;
    cursor: pointer;
    border-bottom: 1px solid $divider-color;

    img {
      width: 100px;
      height: 54px;
    }
  }

  .expandedSectionContent {
    padding: 6px 34px;
  }

  .footer {
    padding: 12px;
  }

  .label {
    color: $text-color;
  }

  @media only screen and (max-width: 768px) {
    margin: 10px;

    .header {
      font-size: 16px;

      img {
        width: 100px;
        height: 56px;
      }
    }
    .expandedSectionContent {
      font-size: 14px;
    }
  }
}

.sentOrderLateModal {
  .title {
    color: #0b71c0;
  }
}
