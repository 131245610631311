.defaultLoginScreen {
  .loginInner {
    width: 100%;
    max-width: 400px;
    margin: 20px;
  }
}

.pinCodeModal {
  .pinSlot {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid;
    font-size: 40px;
    font-weight: bold;

    &:hover {
      background-color: gray;
    }
  }
}

.pincode-login {
  height: 100%;
  background: radial-gradient(circle, #ededed 42%, #e2e2e2 93%);
}
