@import "~app/styles/variables.scss";
@import "~app/components/SideBar/mixins.scss";

.route-content {
  width: calc(100% - #{$sidebar-width});
  margin-right: $sidebar-width;
  border-radius: 0 60px 60px 0;
  padding: 25px;
  background: #f2f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include sidebar-disabled {
    width: 100%;
    margin-right: auto !important;
    border-radius: initial !important;
  }

  > div > h2 {
    font-size: 40px;
  }

  &.hasTopBar {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@include sidebar-detached {
  .route-content {
    width: 100%;
    margin-right: auto !important;
    border-radius: initial !important;
    padding: 5px 20px;
    min-height: calc(100vh - 55px);
  }
}

@media only screen and (max-width: 768px) {
  .route-content {
    padding: 15px;
  }
}

@media only screen and (max-width: 440px) {
  .route-content {
    width: 100%;
    padding: 10px;
  }

  .route-content > h2:first-of-type {
    margin-top: 0;
    text-align: center;
  }
}

// Support bottom nav bar
body.isBottomNavbarActive .route-content {
  padding-bottom: $bottom-navbar-height;
}

.RouteContentTopBar {
  background-color: #17a2d6;
  border-radius: 0 60px 0 0;
  overflow: hidden; // So the border radius will cut off any overflown elements.
  margin-bottom: 8px;

  .title {
    font-size: 24px;
    color: white;
    display: flex;
    align-items: center;
    padding: 12px 24px;
  }

  .iconWrap {
    margin-right: auto;
  }

  @include sidebar-detached {
    border-radius: 0 0 18px 18px;

    .title {
      padding: 12px;
    }
  }
}

.RouteContentTopNav {
  display: flex;

  .Item {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 20px;
    color: white;
    cursor: pointer;

    &.active {
      background-color: #0b71c0;
      cursor: default;
    }
  }
}
