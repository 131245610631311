.full-screen-loader {
  .modal-content {
    // Bootstrap wrapper overrides
    background-color: transparent;
    border: unset;
    justify-content: center;
    align-items: center;
  }

  $speed2x: 2s;
  $speed3x: 1s;
  $speed4x: 0.5s;

  $spinner-size: 60px !default;
  $spinner-color: #106784 !default;
  $spinner-accent: lightblue !default;

  @mixin display-box($size) {
    width: $size;
    height: $size;
    display: inline-block;
    box-sizing: border-box;
  }

  @mixin display-box-wh($width, $height) {
    width: $width;
    height: $height;
    display: inline-block;
    box-sizing: border-box;
  }

  @mixin draw-borders() {
    border-radius: 50%;
    content: " ";
    @include display-box($spinner-size);
    border-top: solid 6px transparent;
    border-bottom: solid 6px transparent;
    position: absolute;
    top: -18px;
    left: -18px;
  }

  @mixin border($width, $colorTop, $colorRight, $colorBottom, $colorLeft) {
    border-top: solid $width $colorTop;
    border-right: solid $width $colorRight;
    border-bottom: solid $width $colorBottom;
    border-left: solid $width $colorLeft;
  }

  .spinner {
    @include display-box($spinner-size);
    position: relative;
  }

  .spinner.round {
    &::before {
      border-radius: 50%;
      content: " ";
      @include display-box($spinner-size);
      @include border(
        $spinner-size / 8,
        $spinner-accent,
        $spinner-accent,
        $spinner-accent,
        $spinner-accent
      );
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      border-radius: 50%;
      content: " ";
      @include display-box($spinner-size);
      @include border(
        $spinner-size / 8,
        $spinner-color,
        transparent,
        transparent,
        transparent
      );
      position: absolute;
      top: 0;
      left: 0;
      animation: round-animate $speed3x ease-in-out infinite;
    }
  }

  @keyframes round-animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
