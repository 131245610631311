.typography {
  &[data-variant="route-title"] {
    color: #484848;
    border-bottom: 1px solid gray;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  &[data-variant="description"] {
    font-size: 16px;
  }
}
