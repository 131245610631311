.h7 {
  font-size: 0.9rem;

  /* START - Bootstrap properties for all text classes */
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  /* END - Bootstrap properties for all text classes */
}

.h8 {
  font-size: 0.8rem;

  /* START - Bootstrap properties for all text classes */
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  /* END - Bootstrap properties for all text classes */
}
