@import "~app/styles/variables.scss";

.DepartmentList {
  justify-content: center;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    padding: 20px;
    flex-basis: auto;
    line-height: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px #c2c2c2;
    margin: 8px;
    font-size: 22px;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    width: 26vw;
    max-width: 350px;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 10px 5px #a0a0a0;
      transition: all 200ms ease;
    }
    .text {
      margin-top: 20px;
      font-weight: bold;
      color: $blue-1;
    }

    img {
      max-height: 100px;
      max-width: 100%;
    }

    button {
      width: 100%;
      line-height: 20px;
      padding: 10px 5px;
      background: none;
      border: 0;
      outline: none;
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: 440px) {
  .DepartmentList {
    .basicPickContainer {
      width: 44vw;
      padding: 8px;
      font-size: 18px;
      margin: 5px;
    }
  }
}
