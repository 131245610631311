html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  padding-bottom: 20px;
  direction: rtl;
  text-align: right;
  background-color: #0e5298;
  font-family: Heebo-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.imgFullWidth {
  width: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}

@font-face {
  font-family: "Heebo-Regular";
  src: local("Heebo-Regular"), url(../fonts/Heebo-Regular.woff) format("woff");
}
