.numpad-modal-content {
  .num-slot {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid;
    font-size: 45px;
    font-weight: bold;

    &:hover {
      background-color: gray;
    }
  }
  .title {
    text-align: center;
    font-size: 30px;
    background: #106784;
    color: white;
    padding: 5px 0 5px 0;
  }
  .number {
    font-size: 38px;
    text-align: center;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    font-weight: bold;
  }
}
