.mobile-collapse {
  .toggle-button {
    box-shadow: none;
    transition: transform 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
    transform: rotate(0deg);
    margin-bottom: 0;

    &.expanding {
      margin-bottom: 14px;
      transform: rotate(180deg);
    }
  }
}
