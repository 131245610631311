@import "~app/styles/variables.scss";

.pickIngredientItem {
  margin: 8px 10px;
  padding: 5px 10px;
  font-size: 14px;
  text-align: right;
  width: 100%;
  // flex-basis: auto;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  box-shadow: 0 0 16px 5px #c4c4c4;

  > div {
    flex: 1;

    &:first-child {
      flex-basis: 55%;
      margin-bottom: 5px;

      > div:first-child {
        display: flex;
      }
    }

    &:last-child {
      flex-basis: 45%;
    }

    .item-header {
      color: $blue-2;
      font-weight: bold;
      font-size: 16px;
      flex-grow: 1;
    }
  }

  .inlineAmounter {
    input {
      color: $blue-2;
    }
  }

  .item-image {
    width: 65px;
    height: 65px;
    padding: 5px;
    margin-left: 10px;
    box-shadow: 0 0 3px 3px #d2d2d2;
    border-radius: 15px;
    border: 3px solid lightgray;
    margin-top: 5px;
  }

  .add-to-order-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
    background: linear-gradient(#0881d5, #0e559c);
    border-radius: 5px;
    line-height: 26px;
    height: 100%;
    padding: 8px;
    text-align: center;
  }

  &.inactive {
    pointer-events: none;
    opacity: 0.4;
  }

  &.new-item {
    background: linear-gradient(
      180deg,
      rgb(191, 255, 152) 0%,
      rgb(193, 193, 193) 100%
    );
  }

  .custom-select {
    color: $blue-2;
    padding: 0 7px 0 30px;
    height: 30px;
  }

  &.no-supplier {
    text-align: center;
    margin: 10px;
  }

  .ingredientName {
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    margin: 3px;
    font-size: 16px;
  }

  .supply-section {
    padding: 10px;
    border: 1px solid #ffd88e;
    border-radius: 5px;

    .supplyInfo {
      margin: 0 auto;
    }
  }

  .dots-flex-container {
    margin-top: 10px;
    width: 50%;

    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
    }
  }

  .pick-ingredient-middle-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pick-ingredient-image {
      height: 150px;
      flex-grow: 1;
      max-width: 30%;
      text-align: left;

      .base-image {
        height: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
          height: auto;
          width: auto;
        }
      }
    }
  }

  [data-no-supplier] .newQuantityControl {
    visibility: collapse;
  }

  #suppliers {
    border: 0;
    border-radius: 0;
    margin-top: 7px;
  }

  .info-row {
    display: flex;
    align-items: center;

    > div:first-child {
      margin-left: 3px;
      display: flex;
    }

    > div:last-child {
      margin-right: 2px;
      font-weight: bold;
      color: $blue-2;
    }
  }

  .current-supply-method {
    display: flex;
    font-size: 18px;
    text-align: center;
    justify-content: space-between;

    > div {
      flex-grow: 1;
    }

    > span {
      font-weight: bold;
      color: $blue-2;
    }
  }
}

@media only screen and (min-width: 1280px) {
  .pickIngredientItem {
    width: 410px !important;
    margin: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .pickIngredientItem {
    width: 45%;
    margin: 8px;
  }
}

@media only screen and (max-width: 440px) {
}

// This is outside of .pickIngredientItem since tooltips rendered inside body...
.order-history-tooltip .tooltip-inner {
  max-width: 280px;
}
