@import "~bootstrap/scss/bootstrap-grid";

.ve-editor-step {
  display: flex;
  align-items: flex-start;
  $carousel-arrows-width: 85px;

  .square-list-item {
    width: 180px;
    height: 180px;
    padding: 6px;

    background-color: #fff;
    margin-bottom: 4px;
    margin-right: 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid lightgray;
    border-radius: 3px;

    img {
      width: 100px;
    }

    @media only screen and (max-width: 899px) {
      width: 100px;
      height: 100px;
      padding: 3px;

      img {
        width: 50px;
      }
    }

    @media only screen and (max-width: 1699px) and (min-width: 900px) {
      width: 120px;
      height: 120px;
      padding: 4px;

      img {
        width: 60px;
      }
    }

    @media only screen and (max-width: 2000px) and (min-width: 1700px) {
      width: 150px;
      height: 150px;
      padding: 5px;

      img {
        width: 80px;
      }
    }
  }

  .left-wing {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .square-list-item {
      width: 15vw;
      height: 15vw;
    }
  }

  .middle-area {
    flex-basis: 35%;
    min-width: 300px;
    padding-right: calc(#{$carousel-arrows-width} + 12px);
  }

  .right-wing {
    flex-basis: 40%;
    align-self: stretch;
    min-width: 0;
    display: flex;
    flex-direction: column;

    .rightWingHeader {
      margin-right: 64px; // 60px + 4px
      margin-left: 4px;
    }

    .carousel {
      /* BS class */
      flex-grow: 1;

      // By default hide carousel arrows
      .carousel-control-prev,
      .carousel-control-next {
        display: none;
      }
    }

    @media only screen and (min-width: 900px) {
      .carousel {
        /* BS class */

        margin-right: 60px; // Margin right when sidebar not floating.

        .carousel-control-prev,
        .carousel-control-next {
          display: flex;
        }

        // Adjust size and looks of arrows layout.
        .carousel-control-prev,
        .carousel-control-next {
          background-color: rgba(226, 226, 234, 0.8);
          width: $carousel-arrows-width;

          > span {
            width: 60px;
            height: 60px;
          }
        }

        .carousel-control-prev {
          right: 100%;
        }

        .carousel-control-next {
          right: -$carousel-arrows-width;
        }
      }
    }

    .right-wing-slide {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .items-stage {
    display: flex;
    > * {
      margin: 4px;
      border: 2px solid;
      border-radius: 4px;
      border-color: lightgray;
      transition: box-shadow 0.5s;

      &:hover {
        box-shadow: 0px 3px 4px 0px;
      }

      .base-image {
        width: 12vh;
        height: 12vh;
      }
    }
  }
}

.ve-done-modal {
  height: 80%;

  .modal-content {
    height: 100%;

    .fade-out {
      animation: fade-out 0.5s ease-out forwards;
    }

    .fade-in {
      animation: fade-in 0.5s ease-in forwards;
    }

    .vibrate {
      animation: vibrate 0.3s linear 6 both;
    }

    .roll-in-right {
      animation: roll-in-right 1s ease-out both;
    }

    .roll-in-left {
      animation: roll-in-left 1s ease-out both;
    }

    .blink {
      animation: blink 1s infinite both;
    }

    @keyframes blink {
      0%,
      50%,
      100% {
        opacity: 1;
      }
      25%,
      75% {
        opacity: 0;
      }
    }

    @keyframes roll-in-right {
      0% {
        transform: translateX(800px) rotate(540deg);
        opacity: 0;
      }
      100% {
        transform: translateX(0) rotate(0deg);
        opacity: 1;
      }
    }

    @keyframes roll-in-left {
      0% {
        transform: translateX(-800px) rotate(-540deg);
        opacity: 0;
      }
      100% {
        transform: translateX(0) rotate(0deg);
        opacity: 1;
      }
    }

    @keyframes vibrate {
      0% {
        transform: translate(0);
      }
      20% {
        transform: translate(-10px, 10px);
      }
      40% {
        transform: translate(-10px, -10px);
      }
      60% {
        transform: translate(10px, 10px);
      }
      80% {
        transform: translate(10px, -10px);
      }
      100% {
        transform: translate(0);
      }
    }

    @keyframes fade-out {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .wrap {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .done-dish-section {
        opacity: 0;
      }

      .position-relative {
        display: flex;
        align-items: center;
        justify-content: center;

        > * {
          position: absolute;
          width: 200px;
          left: -100px;
        }
      }
    }
  }
}

.FoodcostChart {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  canvas {
    position: absolute;
  }

  .foodcost-text {
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20%;
    position: absolute;
    font-size: 24px;
  }
}
