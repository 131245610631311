.DetailedOrderCard {
  $header-text-color: #0d477c;
  $text-color: #124b7f;
  $border-radius: 14px;
  $divider-color: #e8e8e8;

  background-color: white;
  border: 1px solid $divider-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  transition: border-radius 0.5s;
  will-change: border-radius;

  margin: 5px 20px;
  overflow: hidden; // To hide the image overflow.

  .header {
    color: $header-text-color;
    font-size: 18px;
    cursor: pointer;
    border-bottom: 1px solid $divider-color;

    img {
      width: 100px;
      height: 54px;
    }
  }

  .expandedSectionContent {
    // padding: 8px 20px 8px 8px;

    .itemsTable {
      width: 100%;

      thead {
        background-color: #ecf7ff;
      }

      th {
        height: 50px;
        border-bottom: thin solid $divider-color;
        padding: 0 24px 0 0;
        color: $text-color;
        line-height: 1;
      }

      td {
        color: $text-color;
        border-bottom: thin solid $divider-color;
        padding: 12px;
      }

      th.imageName {
        width: 60px;
      }

      th.amount {
        width: 170px;
      }

      th.totalAmount {
        min-width: 70px;
      }

      th.totalCost {
        min-width: 70px;
      }

      td.name {
        color: $text-color;
      }

      td.imageName {
        > * {
          border-radius: 10px;
          overflow: hidden;
          border: 3px solid lightgray;
          width: 60px;
        }
      }
    }
  }

  .footer {
    display: flex;
    padding: 12px;
    gap: 12px;

    .footerLabel {
      color: $text-color;
    }
  }

  &.isExpanded {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-color: #17a2d6;
  }

  @media only screen and (max-width: 768px) {
    margin: 10px;
    .header {
      font-size: 16px;
      img {
        width: 100px;
        height: 56px;
      }
    }
    .expandedSectionContent {
      .expandFooterSection {
        padding: 10px;
      }
      // padding: 8px 20px 8px 8px;
      font-size: 14px;
      .itemsTable {
        width: 100%;

        th {
          height: 40px;
          padding: 0;
          text-align: center;
        }

        td {
          padding: 3px;
        }

        th.imageName {
          width: 40px;
        }

        th.amount {
          width: 110px;
        }

        th.totalCost,
        th.totalAmount {
          width: 50px;
          min-width: auto;
        }

        td.totalCost,
        td.totalAmount {
          text-align: center;
        }
        td.imageName {
          > * {
            width: 40px;
          }
        }

        .inlineAmounter {
          height: 30px;
          input {
            font-size: 16px;
          }
          > div {
            width: 25px;
            margin: 0 1px;

            &.minus {
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}
