@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.SentOrderScreen {
  $text-color: #124b7f;

  .content {
    margin: 0 20px;

    @include media-breakpoint-down(md) {
      margin: 0 12px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 6%;
    }
  }

  .topCard {
    $header-text-color: #0d477c;
    $border-radius: 14px;
    $divider-color: #e8e8e8;

    padding-bottom: 8px;
    background-color: white;
    border: 1px solid $divider-color;
    border-radius: $border-radius;

    .react-select__placeholder,
    .react-select__single-value,
    .form-control,
    .form-control::placeholder {
      color: $text-color;
    }

    .header {
      font-size: 18px;
      border-bottom: 1px solid $divider-color;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      overflow: hidden; // To hide the image overflow.

      margin-bottom: 18px;

      img {
        width: 100px;
        height: 59px;
      }
    }

    @include media-breakpoint-down(md) {
      .header {
        font-size: 16px;
      }

      .sectionContent {
        padding: 0 10px;
        font-size: 14px;
      }
    }

    @include media-breakpoint-up(lg) {
      .sectionContent {
        padding: 0 2%;
      }
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;

    .itemCard {
      $border-radius: 14px;

      background-color: #fff;
      border-radius: $border-radius;
      margin: 0 4px 16px;
      padding: 2% 2% 24px;

      .imageWrap {
        $borderSize: 4px;
        $negativeMargin: 2px;

        position: relative;
        height: 0;
        width: calc(100% - #{($borderSize * 2) - $negativeMargin});
        margin-right: -$negativeMargin;
        padding-top: 100%;
        border: $borderSize solid #dcd9d9;
        border-radius: 16px;
        box-shadow: 2px 2px 4px #00000029;
        box-sizing: content-box; // to be exactly a square
        overflow: hidden; // To cut image corners since this is rounded.
      }

      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }

      .name {
        height: 60px;
        overflow-y: hidden;
        font-size: 18px;
      }

      .amount {
        font-size: 20px;
      }

      &.not-received .amount {
        color: #e04d46;
      }

      &.partially-received .amount {
        color: #ef9635;
      }

      &.received .amount {
        color: #52d058;
      }

      @include media-breakpoint-down(xs) {
        flex-basis: calc(33% - (#{4px} * 2));
        &:nth-child(3n - 2) {
          margin-right: 0;
        }

        &:nth-child(3n) {
          margin-left: 0;
        }
      }

      @include media-breakpoint-between(sm, md) {
        .amount {
          font-size: 19px;
        }

        flex-basis: calc(25% - (#{4px} * 2));
        &:nth-child(4n - 3) {
          margin-right: 0;
        }

        &:nth-child(4n) {
          margin-left: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        margin: 0 8px 16px;

        .name {
          height: 65px;
          font-size: 24px;
        }

        flex-basis: calc(20% - (#{8px} * 2));
        &:nth-child(5n - 4) {
          margin-right: 0;
        }

        &:nth-child(5n) {
          margin-left: 0;
        }
      }

      @include media-breakpoint-up(xl) {
        padding: 3% 3% 24px;
        margin: 0 12px 16px;

        .name {
          font-size: 26px;
        }

        flex-basis: calc(20% - (#{12px} * 2));
        // max-width: 400px;
      }
    }
  }

  .label {
    color: $text-color;
  }
}
