.contracts-table {
  .cardsTableBodyWrap .cards-table {
    width: 400px;
  }

  @media only screen and (max-width: 440px) {
    .cardsTableBodyWrap .cards-table {
      width: 100%;
    }

    .cardsTableBodyWrap .cards-table [data-col="Id"] {
      display: table-cell;
    }
  }
}

#contractsForm {
  .d-flex input {
    min-width: 0;
    text-align: right;
  }

  .contract-titles-flex {
    max-width: 545px;
    justify-content: space-around;
    font-weight: bold;
  }

  @media only screen and (max-width: 440px) {
    .contract-titles-flex {
      width: calc(100% - 70px);
    }

    .contract-titles-flex > * {
      flex-basis: 0;
      flex-grow: 1;
      text-align: center;
    }
  }
}
