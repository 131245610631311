.add-to-stock {
  .ing-table {
    td {
      vertical-align: middle;
    }
  }
}

.StockRemovalRequestScreen {
  .filter-input {
    font-size: 18px;
    padding: 0 34px 0 24px;
    box-shadow: none !important;
    border: 0;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #b9b9b9;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b9b9b9;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #b9b9b9;
    }
  }

  .input-icon {
    position: absolute;
    font-size: 24px;
    color: #1297d7;

    &.left-side {
      left: 6px;
    }

    &.right-side {
      right: 6px;
    }
  }

  .action-icon {
    color: #b7b7b7;
    font-size: 24px;
    opacity: 0.6;
    margin: 0 6px;

    &.active {
      opacity: 1;
    }
  }

  .searchItem {
    width: 160px;
    border: 2px solid;
    border-radius: 4px;
    border-color: lightgray;
    &:hover {
      box-shadow: 0px 3px 4px 0px;
    }
    transition: box-shadow 0.5s;
  }
}
