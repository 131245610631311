@import "~app/styles/variables.scss";

.sidebar {
  height: 100%;
  width: $sidebar-width;
  position: fixed;
  background-color: #0e5298;
  padding-top: 0;
  transition: 0.2s;
  top: 0;
  z-index: 10;

  &.opened {
    right: 0;
    transition: 0.4s;

    .toggleNavButton {
      background: #94c23d;
    }
  }

  .toggleNavButton {
    position: absolute;
    top: 0;
    left: -50px;
    background: #0e5298;
    width: 50px;
    height: 55px;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-bottom: none;
  }

  .divider {
    height: 2px;
    width: 100%;
    margin: 12px 0;
    background-color: lightgray;
  }

  .userTextButton {
    color: lightgray;
    text-decoration: underline;
    cursor: pointer;
  }

  .items {
    height: 100%;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 1280px) {
  .sidebar {
    right: -$sidebar-width;
  }

  .sidebar .toggleNavButton {
    display: flex;
  }
}
