.round-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0 4px 6px 0 #666666c9;
  opacity: 0.85;
  background: white;
  color: #92c140;
  border: 2px solid #92c140;

  &:focus {
    outline: none;
  }

  &.clickable {
    cursor: pointer;
  }

  &:not(.clickable) {
    cursor: default;
    opacity: 0.6;
  }

  &.danger {
    color: red;
    border: 2px solid red;
  }
}
