table.my-schedule-request {
  border-collapse: separate;
  border-spacing: 5px;

  th {
    text-align: center;
  }
  td.shift-day {
    border-radius: 5px;
    height: 80px;
    width: 120px;
    &.status-0 {
      background-color: lightgreen;
    }
    &.status-1 {
      background-color: yellow;
    }
    &.status-2 {
      background-color: red;
      color: white;
    }

    .original-times-section {
      font-size: 14px;
    }

    .bottom-section {
      position: relative;

      .filled-times-section {
        font-size: 14px;
        color: red;
        font-weight: bold;
      }

      .fa-gear {
        position: absolute;
        top: 1px;
        left: 4px;
      }
    }
  }
}
