@import "~app/styles/variables.scss";
@import "~app/components/SideBar/mixins.scss";
@import "./OrdersStatusScreen.scss";
@import "./DetailedOrderCard.scss";
@import "./DepartmentList.scss";
@import "./PickIngredients.scss";
@import "./OrdersBottomNavbar.scss";

.AddOrderHomeScreen,
.OrdersHomeScreen {
  .items {
    display: flex;
    padding: 8px;
    flex-wrap: wrap;

    .item {
      flex-basis: 260px;
      max-width: 46%;
      padding: 6% 0;
      background-color: #fff;
      margin: 5px;
      align-self: flex-start;
      border-radius: 10px;
      cursor: pointer;
      box-shadow: 0px 0px 4px 2px #cbcbcb;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:hover {
        box-shadow: 0px 0px 10px 5px #a0a0a0;
        transition: all 200ms ease;
      }

      img {
        width: 130px;
      }

      .text {
        color: $blue-1;
        font-size: 22px;
        text-align: center;
        line-height: 1;
        font-weight: bold;
      }
    }
  }
}

.OrdersHomeScreen {
  .topBar {
    background-color: #17a2d6;
    border-radius: 0 60px 0 0;
    padding: 24px 36px;

    @include sidebar-detached {
      border-radius: 0 0 18px 18px;
      padding: 14px 24px;
    }
  }

  .items .item {
    flex-basis: 46%;
  }
}

.OrderSupplierList {
  justify-content: center;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  color: $blue-2;

  .item {
    display: flex;
    padding: 0 0 20px;
    flex-basis: auto;
    line-height: 20px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 0px 4px 2px #cbcbcb;
    margin: 3px 8px;
    margin-bottom: 10px;

    font-size: 20px;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    width: 30vw;
    max-width: 340px;

    &:hover {
      box-shadow: 0px 0px 10px 5px #a0a0a0;
      transition: all 200ms ease;
    }

    .item-background {
      width: 100%;
      border-radius: 15px 15px 0 0;
    }

    .pick-title {
      margin-top: 10px;
      padding: 10px;
      font-weight: bold;
    }

    img {
      max-height: 100px;
      max-width: 100%;
      height: 120px;
    }

    button {
      width: 100%;
      line-height: 20px;
      padding: 10px 5px;
      background: none;
      border: 0;
      outline: none;
      box-shadow: none;
    }

    @media only screen and (min-width: 1281px) {
      width: 25vw;
    }
  }
}

.AddOrderTopBar {
  background-color: #17a2d6;
  border-radius: 0 60px 0 0;
  padding: 24px 36px;
  margin-bottom: 20px;

  @include sidebar-detached {
    border-radius: 0 0 18px 18px;
    padding: 14px 24px;
  }

  .filter-input {
    font-size: 18px;
    padding: 0 34px 0 24px;
    box-shadow: none !important;
    border: 0;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #b9b9b9;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #b9b9b9;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #b9b9b9;
    }
  }

  .input-col {
    position: relative;
  }

  .input-icon {
    position: absolute;
    font-size: 24px;
    color: #1297d7;

    &.left-side {
      left: 6px;
    }

    &.right-side {
      right: 6px;
    }
  }

  .action-icon {
    color: #b7b7b7;
    font-size: 24px;
    opacity: 0.6;
    margin: 0 6px;

    &.active {
      opacity: 1;
    }
  }
}

.OrderSupplierInfoCard,
.OrderDepartmentInfoCard {
  $border-radius: 14px;

  border: 1px solid #e8e8e8;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  transition: border-radius 0.5s;
  will-change: border-radius;

  margin: 5px 20px;
  overflow: hidden; // To hide the image overflow.

  .header {
    color: $blue-1;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    img {
      height: 40px;
    }
  }

  .expanded-section-content {
    padding: 8px 20px 8px 8px;

    .info-text {
      color: $blue-1;
      font-size: 14px;
    }
  }

  &.isExpanded {
    .header {
      border-bottom: 1px solid #e8e8e8;
    }

    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

// TODO - move from here..
.btn-primary {
  background-color: #17a2d6;
  border-color: #40b0da;
}
