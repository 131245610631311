#root {
  width: 100%;
  height: 100%;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: default;
}

.btn-xl {
  padding: 0.6rem 1.8rem;
  font-size: 2.1rem;
  line-height: 1.6;
  border-radius: 0.4rem;
}

body {
  overflow-y: overlay;
}

body[stand-type="receiveOrder"] {
  overflow-y: auto;
  &::-webkit-scrollbar-button:vertical:increment {
    background-image: url("../assets/wheel-down.webp");
    transform: scaleX(-1);
  }

  &::-webkit-scrollbar-button:vertical:decrement {
    background-image: url("../assets/wheel-up.webp");
  }

  &::-webkit-scrollbar {
    width: 40px;
    height: 40px;
  }

  &::-webkit-scrollbar-button {
    width: 40px;
    height: 40px;
    background-size: 100%;
    background-color: #9ec659;
  }

  &::-webkit-scrollbar-thumb {
    background: #9ec659;
  }

  &::-webkit-scrollbar-track {
    background: #e5e5e5;
  }
}
