.inlineAmounter {
  font-size: 30px;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 35px;
  line-height: 25px;
  user-select: none;
  position: relative;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 35px;
    text-align: center;
    background: red;
    border-radius: 6px;
    margin: 0 2px;
    font-weight: bol;

    &.plus {
      background: linear-gradient(#53cd90, #51d064);

      color: white;
    }

    &.minus {
      background: orange;
      color: white;
      background: linear-gradient(#edaf31, #f09429);
      font-size: 60px;
    }
  }

  div[data-op="value"] {
    flex-grow: 1;
  }

  input {
    vertical-align: top;
    text-align: center;
    font-weight: bold;
    border: 1px solid lightgray;
    font-size: 24px;
    padding: 0;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    border-radius: 5px;
    background-color: rgb(240, 240, 240);
    color: #124b7f;
  }

  /* Remove number arrows */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /*Hide input blinking cursor*/
  input {
    caret-color: transparent;
  }
}
@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 440px) {
}
