.carousel-full-screen {
  max-width: 75vw;

  /* Bootstrap class */
  .modal-content {
    background: transparent;
    border: unset;

    img {
      max-height: 80vh !important;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      width: 50px;
      height: 50px;
    }

    .carousel-indicators li {
      width: 50px;
      height: 6px;
    }
  }
}
