.VideoTheaterSupport {
  max-width: 75vw;

  /* Bootstrap class */
  .modal-content {
    background: transparent;
    border: unset;

    video {
      max-height: 80vh !important;
    }
  }
}
