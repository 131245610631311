.tree-info-foods-table {
  td {
    vertical-align: middle;
  }
}

.chartContainer {
  position: relative;
}

.table-pie-container {
  display: flex;

  > div {
    flex-grow: 1;
  }
  .chartContainer {
    width: 30%;
  }
}

.table-container {
  overflow: scroll;
}

.tree-info-header-container {
  background: white;
  padding: 15px;
  border-radius: 20px;

  .base-image {
    img {
      max-height: 200px;
      width: auto;
    }
  }
}
@media only screen and (max-width: 1480px) {
  .table-pie-container {
    flex-direction: column;
    .chartContainer {
      width: 70%;
    }
  }
}

/* happens on mobile and less */
@media only screen and (max-width: 440px) {
  .table-pie-container {
    .chartContainer {
      width: 100%;
    }
  }

  .table-container table {
    min-width: 800px;
  }
}
