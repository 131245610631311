.recipe {
  min-width: 80%;

  .ingredients-table {
    border-collapse: separate;
    border-spacing: 1em;
  }

  .photo-wrap {
    width: 120px;
  }

  .steps-container i {
    color: red;
  }

  .font-control {
    width: 30px;
    border-radius: 50%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    border: solid black 1px;
    cursor: pointer;
    user-select: none;
  }
}
