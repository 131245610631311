.ScalesButton {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  text-align: center;

  &.big {
    img {
      height: 120px;
      border-radius: 15px;
      box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.75);
    }
  }

  &.small {
    img {
      height: 30px;
    }
  }

  img {
    background: #ffe998;
    padding: 1px;
    border: 1px solid lightgrey;
    transition: all 0.3s;

    &:hover {
      background: #f1be00;
    }
  }
}
