.RemoteWeightModal {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;

  .content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    position: relative;

    > .received-data {
      font-size: 30px;
      margin-bottom: 20px;
    }

    > .received-data span {
      font-weight: bold;
    }

    > div:first-child {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
}
