.base-image {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }

  &.rounded {
    img {
      overflow: hidden;
      border-radius: 50%;
    }
  }
}
