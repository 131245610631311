.amount-input {
  font-size: 28px;
  cursor: pointer;
  display: flex;
  height: 30px;
  line-height: 25px;
  user-select: none;
  margin-top: 0;
  max-width: 140px;

  div[data-op="value"] {
    flex-grow: 1;
  }

  input {
    vertical-align: top;
    text-align: center;
    font-weight: bold;
    border: 1px solid lightgray;
    font-size: 14px;
    padding: 0;
    height: 100%;
    flex-grow: 1;
    width: 100%;
    min-width: 60px;
  }

  > .plus,
  > .minus {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 30px;
    background: lightgray;
  }

  /* Remove number arrows */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /*Hide input blinking cursor*/
  input {
    caret-color: transparent;
  }
}
