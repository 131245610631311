.CardsTable {
  position: relative;
  min-height: 120px;

  .loadingWrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #f2f2f2, $alpha: 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cardsTableTopBar {
  input {
    max-width: 280px;
  }
}
.cardsTableBodyWrap {
  overflow-x: auto;
  position: relative;
  min-height: 120px;

  .sticky-outer-wrapper.active {
    .sticky-node {
      background-color: #8fbf3f;
      z-index: 1;
      padding: 8px 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: white;
    }
  }

  .cards-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
    font-size: 16px;

    [role="columnheader"] {
      text-align: center;
    }

    thead tr {
      font-weight: bold;
      color: gray;
    }

    thead th {
      padding: 0;
    }

    tbody tr {
      background: #ffffff;
      opacity: 1;
      transition: opacity 300ms ease-in-out;
      cursor: pointer;
      transition: all linear 200ms;
    }

    tbody tr:hover {
      background: #92c140;
    }

    tbody tr img {
      height: 80px;
      border-radius: 10px;
      max-width: 100px;
    }

    tbody tr td {
      padding: 10px 5px !important;
      position: relative;
      text-align: center;
    }

    tbody tr td:first-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    tbody tr td:last-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    tbody tr td.table-closer {
      font-size: 0;
      width: 5px;
      padding: 0 !important;
    }

    .name-col {
      max-width: 200px;
      width: 200px;
    }

    .name-col > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    tbody .name-col {
      text-align: right;
      font-weight: 500;
      padding-right: 10px !important;
    }

    tbody .action-col {
      padding-left: 10px;
      padding-right: 10px;
      min-width: 60px;
    }

    tbody tr[data-line-through] {
      text-decoration: line-through;
    }

    /* happens on tablets and less */
    @media only screen and (max-width: 768px) {
      width: 95%;
      border-spacing: 0 15px;

      tbody tr img {
        max-height: 60px;
        max-width: 90px;
        border-radius: 0;
      }

      tbody tr td {
        padding: 0 10px !important;
        position: relative;
        user-select: none;
      }

      .name-col {
        max-width: 130px;
        width: 130px;
        height: 62px;
      }

      tbody tr td:first-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      tbody tr td:last-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      [data-col="Status"] {
        display: none;
      }
    }

    /* happens on mobile and less */
    @media only screen and (max-width: 440px) {
      width: 100%;
      border-spacing: 0 10px;
      font-size: 15px;
      tbody tr img {
        max-height: 50px;
        max-width: 70px;
      }

      [data-col="Id"],
      [data-col="DefaultPrice"],
      [data-col="CurrentStock"],
      [data-col="IsActive"],
      [data-col="HourlyRate"],
      [data-col="salaryType"],
      [data-col="IsGlobalSalary"],
      [data-col="IsPartOfTips"],
      [data-col="ItemsCount"],
      [data-col="SupplierName"],
      [data-col="Amount"] {
        display: none;
      }
    }

    /* happens on medium screens and less */
    @media only screen and (max-width: 1280px) {
      [data-col="PrepareUnitType"],
      [data-col="SubmitterName"] {
        display: none;
      }

      .sticky-outer-wrapper.active {
        .sticky-node {
          padding: 15.5px 0;
        }
      }
    }
  }
}
