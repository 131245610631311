@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.SentOrderScanScreen {
  .card {
    $header-text-color: #0d477c;
    $text-color: #124b7f;
    $border-radius: 14px;
    $divider-color: #e8e8e8;

    margin: 0 20px;
    padding-bottom: 8px;
    background-color: white;
    border: 1px solid $divider-color;
    border-radius: $border-radius;

    .react-select__placeholder,
    .react-select__single-value,
    .form-control,
    .form-control::placeholder {
      color: $text-color;
    }

    .cameraButton {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }

    .header {
      font-size: 18px;
      border-bottom: 1px solid $divider-color;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      overflow: hidden; // To hide the image overflow.

      margin-bottom: 18px;

      img {
        width: 100px;
        height: 59px;
      }
    }

    .sectionContent {
      padding: 6px 34px;
    }

    .label {
      color: $text-color;
    }

    @include media-breakpoint-down(md) {
      margin: 0 8px;

      .header {
        font-size: 16px;
      }

      .sectionContent {
        padding: 0 10px;
        font-size: 14px;
      }
    }

    @include media-breakpoint-up(lg) {
      margin: 0 15%;

      .sectionContent {
        padding: 0 2%;
      }
    }
  }
}
